import { Icon, Icons } from 'elements/Icon/Icon'
import { Link } from 'elements/Link/Link'
import clsx from 'clsx'
import { Boop } from 'animations/Boop'
import { DEFAULT_LOCALE } from '~/config/constants'
import { useNavigationQuery } from '@contentfulTypes'
import { useRouter } from 'next/router'
import { createGraphQLClient } from '~/graphql/contentfulClient'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
type Props = {
  className?: string
}
export const SocialLinks = ({ className }: Props) => {
  const preview = usePreviewMode()
  const contentfulClient = createGraphQLClient({ preview })
  const router = useRouter()
  const { data } = useNavigationQuery(contentfulClient, {
    preview,
    locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
  })
  const socialLinks = data?.pageCollection?.items?.[0]?.socialFooterIconsCollection
  if (!data || !socialLinks) return null

  return (
    <ul className={clsx('grid grid-flow-col justify-center gap-8 lg:justify-start', className)}>
      {socialLinks?.items?.map((item, i) => (
        <li key={item?.sys?.id || i} className={`${item?.icon}-icon`}>
          <Link href={item?.link as string} title={item?.title as string}>
            <Boop>
              <Icon name={item?.icon as Icons} />
            </Boop>
          </Link>
        </li>
      ))}
    </ul>
  )
}
