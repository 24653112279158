import '@reach/accordion/styles.css'
import { CustomAccordionItem } from './CustomAccordionItem'
import { Accordion as AccordionReach } from '@reach/accordion'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

export type Item = {
  itemHeading: string
  richText: string | JSX.Element
}

export type AccordionProps = {
  itemList: Item[]
  classNameAccordionItem?: string
  classNameButton?: string
  className?: string
  classNameHeadline?: string
  onlyApplyForLastElement?: boolean
}

export const Accordion = ({
  itemList,
  classNameAccordionItem,
  classNameButton,
  className,
  classNameHeadline,
  onlyApplyForLastElement,
}: AccordionProps) => {
  const [indices, setIndices] = useState<number[]>([])
  const router = useRouter()

  useEffect(() => {
    const closeAccordion = (_url: string, { shallow }: { shallow: boolean }) => {
      if (!shallow) {
        setIndices([])
      }
    }
    router.events.on('routeChangeStart', closeAccordion)

    return () => {
      router.events.off('routeChangeStart', closeAccordion)
    }
  }, [router])

  const toggleItem = (toggledIndex: number) => {
    if (indices.includes(toggledIndex)) {
      setIndices(indices.filter((currentIndex) => currentIndex !== toggledIndex))
    } else {
      setIndices([...indices, toggledIndex].sort())
    }
  }

  return (
    <section className="flex justify-center">
      <div className="mx-auto w-full max-w-[60rem]">
        <AccordionReach collapsible onChange={toggleItem} className={clsx('w-full', className)}>
          {itemList.map((item, i) => (
            <CustomAccordionItem
              key={item.itemHeading + i}
              itemHeading={item.itemHeading}
              className={
                onlyApplyForLastElement
                  ? i + 1 === itemList.length
                    ? classNameAccordionItem
                    : ''
                  : classNameAccordionItem
              }
              classNameButton={classNameButton}
              index={i}
              classNameHeadline={classNameHeadline}
            >
              {typeof item.richText === 'string' ? (
                <div
                  className={clsx('richText list-item py-4 sm:py-4')}
                  dangerouslySetInnerHTML={{ __html: item.richText }}
                ></div>
              ) : (
                item.richText
              )}
            </CustomAccordionItem>
          ))}
        </AccordionReach>
      </div>
    </section>
  )
}
